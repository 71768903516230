var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4efdf73e2da0a2c0c8b519c7d13ab0f7926e5eb9"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { RewriteFrames } from "@sentry/integrations"
import * as Sentry from "@sentry/nextjs"

const NODE_ENV = process.env.NODE_ENV
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT

const ignoreErrorsList = [
  "Failed to execute 'removeChild' on 'Node'",
  "Failed to execute 'insertBefore' on 'Node'", 
  "chrome-extension", 
  "removeChild",
  "gqlRequest(src/common/helpers/fetcher)",
  "this.retryer.onSuccess",  
  "this.retryer.onSuccess(src/query)",
  "Minified Lexical",  
  "websocket error",  
  "fetch(src/instrument)",  
  "fetchCallImpl",  
  "Cannot read properties of null (reading 'getItem')",  
  "reading 'removeEventListener'",  
  "browser-perf",  
  "p.makeError",  
  "NetworkError when attempting to fetch resource", 
  "window.ReactNativeWebView.postMessage",  
  "chunks/pages/_app", 
  "chunks/src/",
  "Load Failed",
  "GET /mockServiceWorker.js",
  "Cannot write private member",
  "e.listConnectors is not a function"
]


Sentry.init({
  enabled: NODE_ENV === "production",
  dsn: SENTRY_DSN,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.1,

  integrations: [
    new Sentry.BrowserTracing(),
    new RewriteFrames({ 
      iteratee: (frame) => {
        frame.filename.replace("%5B", "[").replace("%5D", "]")

        return frame
      }
    }),
    new Sentry.Replay()
  ],
  tracesSampleRate: 0.1,
  environment: SENTRY_ENVIRONMENT,
  maxValueLength: 2000,
  ignoreErrors: ignoreErrorsList
})
